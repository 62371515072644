import axios from "axios";
import authHeader from "./auth-header";
import { API_URL } from "~/config/axios";

export const getAll = (
  { page, limit }: { page: number; limit: number },
  search: any
) => {
  return axios.post(
    API_URL + "/User/get",
    {
      pageSize: limit,
      pageNumber: page + 1,
      search,
    },
    { headers: authHeader() }
  );
};

export const Create = (data: any) => {
  return axios.post(API_URL + "/User/Create", data, {
    headers: authHeader(),
  });
};

export const Modify = (id: string, data: any) => {
  return axios.put(API_URL + `/User/update?id=${id}`, data, {
    headers: authHeader(),
  });
};
// .Update User Details :
// URL : /Account/UpdateUserFullName
export const UpdateUserDetails = (data: any) => {
  return axios.post(API_URL + `/Account/UpdateUserFullName`, data, {
    headers: authHeader(),
  });
};
export const Get = (id: string) => {
  return axios.get(API_URL + `/User/${id}`, {
    headers: authHeader(),
  });
};
export const GetTableStructure = () => {
  return axios.get(API_URL + `/User/DisplayFieldAlias`, {
    headers: authHeader(),
  });
};

export const Delete = (id: number) => {
  return axios.delete(API_URL + `/User/${id}`, {
    headers: authHeader(),
  });
};

export const BranchList = () => {
  return axios.post(
    API_URL + `/Dropdown/GetDDLListByType`,
    {
      branchId: 0,
      type: "Branch-DDL",
      searchVal1: "",
      searchVal2: "",
      searchVal3: "",
      searchVal4: "",
      searchVal5: ""
    },
    { headers: authHeader() }
  );
};

export const VendorList = () => {
  return axios.post(
    API_URL + "/Dropdown/GetDDLListByType",
    {
      type: "Vendor-List",
      branchId: 0,
      searchVal1: "",
      searchVal2: "",
      searchVal3: "",
      searchVal4: "",
      searchVal5: ""
    },
    {
      headers: authHeader(),
    }
  );
};
export const UserTypeList = () => {
  return axios.post(
    API_URL + `/Dropdown/GetDDLListByType`,
    {
      branchId: 0,
      type: "UserType-DDL",
      searchVal1: "",
      searchVal2: "",
      searchVal3: "",
      searchVal4: "",
      searchVal5: ""
    },
    { headers: authHeader() }
  );
};