import { createContext, useContext, useMemo, FC, ReactNode, useState } from "react";
import axios from "axios";
import {  
  GetPaymentModes,
  GetAllCustomers,
  GetAllAgents,
} from "~/repositories/salesOrder.service";
import { GetCommonDDLList, BranchList,GetPresDDLListByType } from "~/repositories/ddllist.service";
import {  VendorList } from "~/repositories/user.service";
import { 
  GetItemList,
  GetByItemsById,
} from "~/repositories/purchaseorder.service";

interface AppStateInterface {
  promise: {
    worklist_Agents?: any;
    worklist_paymentModes?: any;
    worklist_customers?: any;
    GenderList?: any;
    ItemTypeList?: any;
    ItemCategoryList?: any;
    ItemCategoryListSelect?: any;
    EyeList?: any;
    ItemTypeListSelect?: any;
    VendorList?: any;
    POList?: any;
    Branches?:any;
    LensAxis?: any;
    LensCYL?: any;
    LensSPH?: any;
  };
}

interface AppContextInterface {
  GetAllAgentsData: () => Promise<any>;
  GetPaymentModesData: () => Promise<any>;
  GetCustomersDropDown: () => Promise<any>;
  GetAllDropDown:()=>Promise<any>;
  GetVendorDropDown: () => Promise<any>;
  GetPO: () => Promise<any>;
  GetBranches: () => Promise<any>;
  GetPrescriptionDropDown:()=>Promise<any>;
}

export const workListContext = createContext<AppContextInterface>(
  {} as AppContextInterface
);

interface AppProviderProps {
  children?: ReactNode;
}

export const WorkListProvider: FC<AppProviderProps> = ({ children }) => {
  const [state, setState] = useState<AppStateInterface>({
    promise: {}
  });

  const GetAllAgentsData = () => {
    return new Promise((resolve, reject) => {
      if (state.promise.worklist_Agents) {
        resolve(state.promise.worklist_Agents);
      } else {
        GetAllAgents().then((response) => {
          setState((prevState) => ({
            ...prevState,
            promise: {
              ...prevState.promise,
              worklist_Agents: response
            }
          }));
          resolve(response);
        }).catch((err) => {
          reject(err);
        });
      }
    });
  };

  const GetPaymentModesData = () => {
    return new Promise((resolve, reject) => {
      if (state.promise.worklist_paymentModes) {
        resolve(state.promise.worklist_paymentModes);
      } else {
        GetPaymentModes().then((response) => {
          setState((prevState) => ({
            ...prevState,
            promise: {
              ...prevState.promise,
              worklist_paymentModes: response
            }
          }));
          resolve(response);
        }).catch((err) => {
          reject(err);
        });
      }
    });
  };

  const GetCustomersDropDown = () => {
    return new Promise((resolve, reject) => {
      if (state.promise.worklist_customers) {
        resolve(state.promise.worklist_customers);
      } else {
        let searchVal:any;
        GetAllCustomers(searchVal).then((response) => {
          setState((prevState) => ({
            ...prevState,
            promise: {
              ...prevState.promise,
              worklist_customers: response
            }
          }));
          resolve(response);
        }).catch((err) => {
          reject(err);
        });
      }
    });
  };

  const GetAllDropDown = () => {
    return new Promise((resolve, reject) => {
      if (state.promise.GenderList ||
        state.promise.EyeList||
        state.promise.ItemCategoryList||
        state.promise.ItemCategoryListSelect||
        state.promise.ItemCategoryListSelect ||
        state.promise.ItemTypeList ||
        state.promise.ItemTypeListSelect
      ) {
        resolve(state.promise);
      }     
       else {        
        GetCommonDDLList().then((response) => {
          setState((prevState) => ({
            ...prevState,
            promise: {
              ...prevState.promise,
              GenderList: response.data.GenderList,
              EyeList: response.data.EyeList,
              ItemTypeListSelect: response.data.ItemTypeListSelect,
              ItemTypeList: response.data.ItemTypeList,
              ItemCategoryListSelect: response.data.ItemCategoryListSelect,
              ItemCategoryList: response.data.ItemCategoryList,
            }
          }));
          resolve(response);
        }).catch((err) => {
          reject(err);
        });
      }
    });
  };

  const GetVendorDropDown = () => {
    return new Promise((resolve, reject) => {
      if (state.promise.VendorList) {
        resolve(state.promise.VendorList);
      } else {        
        VendorList().then((response) => {
          setState((prevState) => ({
            ...prevState,
            promise: {
              ...prevState.promise,
              VendorList: response
            }
          }));
          resolve(response);
        }).catch((err) => {
          reject(err);
        });
      }
    });
  };
  const GetPO = () => {
    return new Promise((resolve, reject) => {
      if (state.promise.POList) {
        resolve(state.promise.POList);
      } else {        
        GetItemList().then((response) => {
          setState((prevState) => ({
            ...prevState,
            promise: {
              ...prevState.promise,
              POList: response
            }
          }));
          resolve(response);
        }).catch((err) => {
          reject(err);
        });
      }
    });
  };
  
  const GetBranches = () => {
    return new Promise((resolve, reject) => {
      if (state.promise.Branches) {
        resolve(state.promise.Branches);
      } else {        
        BranchList().then((response) => {
          setState((prevState) => ({
            ...prevState,
            promise: {
              ...prevState.promise,
              Branches: response
            }
          }));
          resolve(response);
        }).catch((err) => {
          reject(err);
        });
      }
    });
  }; 
  const GetPrescriptionDropDown = () => {
    return new Promise((resolve, reject) => {
      if (state.promise.LensAxis ||
        state.promise.LensCYL||
        state.promise.LensSPH
      ) {
        resolve(state.promise);
      }     
       else {        
        GetPresDDLListByType().then((response) => {
          setState((prevState) => ({
            ...prevState,
            promise: {
              ...prevState.promise,
              LensAxis: response.data.LensAxis,
              LensCYL: response.data.LensCYL,
              LensSPH: response.data.LensSPH
            }
          }));
          resolve(response);
        }).catch((err) => {
          reject(err);
        });
      }
    });
  };

  
  const value = useMemo(
    () => ({
      GetAllAgentsData,
      GetPaymentModesData,
      GetCustomersDropDown,
      GetAllDropDown,
      GetVendorDropDown,
      GetPO,
      GetBranches,
      GetPrescriptionDropDown
    }),
    [state]
  );

  return <workListContext.Provider value={value}>{children}</workListContext.Provider>;
};

export const useWorkListContext = () => {
  return useContext(workListContext);
};
