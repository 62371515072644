import { createContext, useContext, useMemo, FC, ReactNode, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useLocalStorage } from "~/hooks/useLocalStorage";

interface AppContextInterface {
  user: any;
  login: (data: any) => Promise<void>;
  logout: () => void;
  version: string;
  subversion: string;
  setVersionFromDB:(data: any) => Promise<void>;
  // verification: (data: any) => Promise<void>;
}

export const AuthContext = createContext<AppContextInterface>(
  {} as AppContextInterface
);

interface AuthProviderProps {
  children?: ReactNode;
}

export const AuthProvider: FC<AuthProviderProps> = ({ children }) => {
  const [user, setUser] = useLocalStorage("user", null);
  const [version, setVersion] = useLocalStorage("version", '1.0.7.18');
  const [subversion, setSubversion] = useLocalStorage("", '');
  const navigate = useNavigate();

  useEffect(() => {
    //setVersion('1.0.7.19')
    setSubversion('1')
  }, [])
  const login = async (data: any) => {
    /* const userAccessPages = data.userModules.map(
      (data: Record<string, any>) => data.menusrno
    );
    const modules = data.userModules.map(
      (data: Record<string, any>) => data.module
    );

    data.userAccessPages = userAccessPages;
    data.modules = modules; */
    setUser(data);
    navigate("/landingPage");
  };

  const logout = () => {
    setUser(null);
    navigate("/", { replace: true });
  };
  const setVersionFromDB= async (data: any) => {
    setVersion(data);
  };
  const value = useMemo(
    () => ({
      user,
      version, 
      subversion,
      login,
      logout,
      setVersionFromDB
      // verification,
    }),
    [user, version, subversion]
  );
  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

export const useAuth = () => {
  return useContext(AuthContext);
};
