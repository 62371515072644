import axios from "axios";
import authHeader from "./auth-header";
import { API_URL } from "~/config/axios";
export const GetAllCustomerList = ({ id }: { id: string }) => {
  return axios.get(
    API_URL + `/DDLList/CustomerList`,

    { headers: authHeader() }
  );
};

export const GetAll = () => {
  return axios.get(
    API_URL + `/DDLList/Dashboard`,

    { headers: authHeader() }
  );
};
export const GetAllState = () => {
  return axios.get(
    API_URL + `/DDLList/StateList`,

    { headers: authHeader() }
  );
};
export const GetDDLListByType = () => {
  return axios.post(
    API_URL + `/Dropdown/GetDDLListByType`,
    {
      branchId: 0,
      type: "Item-Properties-List-All",
      searchVal1: "",
      searchVal2: "",
      searchVal3: "",
      searchVal4: "",
      searchVal5: ""
    },
    { headers: authHeader() }
  );
};
export const GetCommonDDLList = () => {
  return axios.post(
    API_URL + `/Dropdown/GetDDLListByType`,
    {
      branchId: 0,
      type: "Item-Common-DDL",
      searchVal1: "",
      searchVal2: "",
      searchVal3: "",
      searchVal4: "",
      searchVal5: ""
    },
    { headers: authHeader() }
  );
};
export const BranchList = () => {
  return axios.post(
    API_URL + `/Dropdown/GetDDLListByType`,
    {
      branchId: 0,
      type: "Branch-List-BT-NEW",
      searchVal1: "",
      searchVal2: "",
      searchVal3: "",
      searchVal4: "",
      searchVal5: ""
    },
    { headers: authHeader() }
  );
};
export const GetPresDDLListByType = () => {
  return axios.post(
    API_URL + `/Dropdown/GetDDLListByType`,
    {
      branchId: 0,
      type: "Prescription-PROP-List",
      searchVal1: "",
      searchVal2: "",
      searchVal3: "",
      searchVal4: "",
      searchVal5: ""
    },
    { headers: authHeader() }
  );
};