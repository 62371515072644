import axios from "axios";
import authHeader from "./auth-header";
import { API_URL } from "~/config/axios";

export const getAll = (
    { page, limit }: { page: number; limit: number },
    search: any
  ) => {
    return axios.post(
      API_URL + "/Purchase/get",
      {
        pageSize: limit,
        pageNumber: page + 1,
        search,
      },
      { headers: authHeader() }
    );
  };

  export const GetPurchaseItem = (id: string) => {
    return axios.get(API_URL + `/PurchaseItem/Purchaseid/${id}`, {
      headers: authHeader(),
    });
  };

export const CustomerList = (
  { page, limit }: { page: number; limit: number },
  search: any
) => {
  return axios.post(
    API_URL + "/Customer/get",
    {
      pageSize: limit,
      pageNumber: page + 1,
      search,
    },
    { headers: authHeader() }
  );
};

export const GetById = (id: string) => {
  return axios.get(API_URL + `/POMainDetails/${id}`, {
    headers: authHeader(),
  });
};

export const Create = (data: any) => {
  return axios.post(API_URL + "/POMainDetails/Create", data, {
    headers: authHeader(),
  });
};

export const Modify = (id: string, data: any) => {
  return axios.put(API_URL + `/POMainDetails/Update?purchaseorderid=${id}`, data, {
    headers: authHeader(),
  });
};

export const Delete = (id: number) => {
  return axios.delete(API_URL + `/POMainDetails/${id}`, {
    headers: authHeader(),
  });
};

export const GetFrameDetails = () => {
  return axios.get(API_URL + "/Frames/Get_FrameDetails ", {
    headers: authHeader(),
  });
};

export const GetItemList = () => {
  return axios.post(
    API_URL + "/Dropdown/GetDDLListByType",
    {
      type: "Item-List-PO",
      branchId: 0,
      searchVal1: "",
      searchVal2: "",
      searchVal3: "",
      searchVal4: "",
      searchVal5: ""
    },
    {
      headers: authHeader(),
    }
  );
};

export const GetAutoCompleteItemList = (searchVal1: string) => {
  return axios.post(
    API_URL + "/Dropdown/GetDDLListByType",
    {
      type: "Item-List-PO-LK",
      branchId: 0,
      searchVal1: searchVal1,
      searchVal2: "",
      searchVal3: "",
      searchVal4: "",
      searchVal5: ""
    },
    {
      headers: authHeader(),
    }
  );
};

export const getAllInventory = (
  { page, limit }: { page: number; limit: number },
  search: any,
  categoryid: string,
  typeid: string
) => {
  return axios.post(
    API_URL + `/ItemMaster/get?categoryid=${typeid}&typeid=${categoryid}`,
    {
      pageSize: 999999,
      pageNumber: page + 1,
      search,
    },
    { headers: authHeader() }
  );
};

export const PurchaseOrderList = (
  { page, limit }: { page: number; limit: number },
  fromdate: any,
  todate:any,
  search: any
) => {
  return axios.post(
    API_URL + "/POMainDetails/search",
    {
      pageSize: limit,
      pageNumber: page + 1,
      fromdate,
      todate,
      search,
    },
    { headers: authHeader() }
  );
};

// /POMainDetails/GetPOItems?id=3
export const GetByItemsById = (id: number) => {
  return axios.get(API_URL + `/POMainDetails/GetPOItems?id=${id}`, {
    headers: authHeader(),
  });
};

export const CreatePOItem = (data: any) => {
  return axios.post(API_URL + "/POMainDetails/CreatePOItems", data, {
    headers: authHeader(),
  });
};

export const ModifyPOItem = (id: string, data: any) => {
  return axios.post(API_URL + `/POMainDetails/UpdatePOItems?id=${id}`, data, {
    headers: authHeader(),
  });
};

export const DeletePOItem = (id: number) => {
  return axios.delete(API_URL + `/POMainDetails/DeletePOItems?id=${id}`, {
    headers: authHeader(),
  });
};
export const CreateInventoryItem = (id: string, data: any) => {
  return axios.post(API_URL + `/POMainDetails/SavePOItemsList?podetailid=${id}`, data, {
    headers: authHeader(),
  });
};