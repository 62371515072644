import React from "react";
import { Routes, Route } from "react-router-dom";
import { withFallback } from "./withFallback";

const MainLayout = withFallback(
  React.lazy(() => import("~/layouts/MainLayout"))
);
const RegisterLayout = withFallback(
  React.lazy(() => import("~/layouts/RegisterLayout"))
);

const Customers = withFallback(React.lazy(() => import("~/pages/Customers")));
const Notes = withFallback(React.lazy(() => import("~/pages/Notes")));
const Sales = withFallback(React.lazy(() => import("~/pages/Sales")));
const Branches = withFallback(React.lazy(() => import("~/pages/Branches")));
const ItemSettings = withFallback(React.lazy(() => import("~/pages/ItemSettings")));
const ReportsPage = withFallback(React.lazy(() => import("~/pages/Reports")));
const SalesReferralsPage = withFallback(React.lazy(() => import("~/pages/SalesReferralsReport")));
const SalesReferralsSummaryPage = withFallback(React.lazy(() => import("~/pages/SalesReferralsSummaryReport")));
const SalesOrderPage = withFallback(React.lazy(() => import("~/pages/SalesOrder")));
const ReceivePaymentPage = withFallback(React.lazy(() => import("~/pages/ReceivePayment")));
const PurchasePage = withFallback(React.lazy(() => import("~/pages/PurchaseOrderNew")));
const PurchaseAddPage = withFallback(React.lazy(() => import("~/pages/PurchaseOrderNew/AddPurchaseOrder/AddPurchaseOrderNew")));
const SalesOrderAddPage = withFallback(React.lazy(() => import("~/pages/Sales/AddSalesOrder/AddForm")));
const ItemMasterAddPage = withFallback(React.lazy(() => import("~/pages/ItemMaster/AddItemMaster")))
const ItemBranchTransferAddPage = withFallback(React.lazy(() => import("~/pages/ItemBranchTransfer/AddItemBranchTransfer/AddForm")));
const ItemMaster = withFallback(React.lazy(() => import("~/pages/ItemMaster")));
// StockAlertProducts
const StockAlertProducts = withFallback(React.lazy(() => import("~/pages/StockAlertProducts")));
const ItemProperties = withFallback(React.lazy(() => import("~/pages/ItemProperties")));
const BranchStockInventoryPage = withFallback(React.lazy(() => import("~/pages/BranchStockInventory")));
const BranchStockReceivePage = withFallback(React.lazy(() => import("~/pages/BranchStockInventory/ReceiveStockForm/ReceiveStockForm")));
const ItemInventoryPage = withFallback(React.lazy(() => import("~/pages/ItemInventory")));
const AddInventoryForm = withFallback(React.lazy(() => import("~/pages/ItemInventory/AddInventory/AddInventoryForm")));
const VendorPayments = withFallback(React.lazy(() => import("~/pages/VendorPayments")));
const CustomerVisit = withFallback(React.lazy(() => import("~/pages/CustomerVisit")));
const LandingPage = withFallback(React.lazy(() => import("~/pages/Dashboard/indexUpdated")));
// SalesSummaryDashboard
const SalesSummaryDashboard = withFallback(React.lazy(() => import("~/pages/Dashboard/salesSummaryIndex")));


const Products = withFallback(React.lazy(() => import("~/pages/Products")));
const Orders = withFallback(
  React.lazy(() => import("~/pages/Orders/OrderList"))
);
const BackOrder = withFallback(
  React.lazy(() => import("~/pages/Orders/BackOrder"))
);
const BackOrderPage = withFallback(
  React.lazy(() => import("~/pages/Orders/BackOrder/BackOrderPage"))
);
const Invoice = withFallback(
  React.lazy(() => import("~/pages/Orders/Invoice"))
);
const CustomerOrderEdit = withFallback(
  React.lazy(() => import("~/pages/Orders/CustomerOrder/CustomerOrder"))
);
const CustomerOrder = withFallback(
  React.lazy(() => import("~/pages/Orders/CustomerOrder"))
);
const NewOrders = withFallback(
  React.lazy(() => import("~/pages/Orders/NewOrderrs/NewOrderLIst"))
);
const OrdersPage = withFallback(
  React.lazy(() => import("~/pages/Orders/OrdersPage"))
);
const InvoicePage = withFallback(
  React.lazy(() => import("~/pages/Orders/Invoice/InvoicePage"))
);
const NewOrdersPage = withFallback(
  React.lazy(() => import("~/pages/Orders/NewOrderPage"))
);
const Pricings = withFallback(React.lazy(() => import("~/pages/Pricings")));
const AddCustomers = withFallback(
  React.lazy(() => import("~/pages/Customers/ManageCustomers"))
);

const ProductType = withFallback(
  React.lazy(() => import("~/pages/ProductType"))
);
const Vendor = withFallback(React.lazy(() => import("~/pages/Vendor")));
const Agent = withFallback(React.lazy(() => import("~/pages/Agent")))
const ProductCategory = withFallback(
  React.lazy(() => import("~/pages/ProductCategory"))
);

const Login = withFallback(React.lazy(() => import("~/pages/Login")));
const Register = withFallback(React.lazy(() => import("~/pages/Register")));

const ChangePassword = withFallback(
  React.lazy(() => import("~/pages/changePassword/index"))
);

const ChangeName = withFallback(
  React.lazy(() => import("~/pages/changeName/index"))
);
const CheckOut = withFallback(
  React.lazy(() => import("~/pages/checkInOut/index"))
);
const CheckinoutLog = withFallback(
  React.lazy(() => import("~/pages/CheckInOutLog/index"))
);
const BranchWiseSalesSummary = withFallback(
  React.lazy(() => import("~/pages/BranchwiseSalesSummary/index"))
);
const BranchwiseDateReport = withFallback(
  React.lazy(() => import("~/pages/BranchwiseDateReport/index"))
);
const CategoryStockSummary = withFallback(
  React.lazy(() => import("~/pages/CategoryStockSummary")));
  // SoldQtySummary
  const SoldQtySummary = withFallback(
    React.lazy(() => import("~/pages/SoldQtySummary")));  
const ItemStockSummary = withFallback(
 React.lazy(() => import("~/pages/ItemStockSummary")));
    //ItemProductStockAlert 
const ItemProductStockAlert = withFallback(
  React.lazy(() => import("~/pages/ItemStockAlertProductList")));
const User = withFallback(React.lazy(() => import("~/pages/User")));
const UserManager = withFallback(
  React.lazy(() => import("~/pages/User/UserManager"))
);
const DocumentType = withFallback(
  React.lazy(() => import("~/pages/DocumentType"))
);
const ShipType = withFallback(React.lazy(() => import("~/pages/ShipType")));
const SKU = withFallback(React.lazy(() => import("~/pages/SKU")));
const PayementPage = withFallback(
  React.lazy(() => import("~/pages/Orders/PayementPage"))
);
const SearchPendingPackaging = withFallback(
  React.lazy(() => import("~/pages/Orders/SearchPendingPackaging"))
);
const DeliveredOrders = withFallback(
  React.lazy(() => import("~/pages/Orders/SearchDeliveredOrders/Delivered"))
);
const SearchDeliveredOrders = withFallback(
  React.lazy(() => import("~/pages/Orders/SearchDeliveredOrders"))
);
const PendingConfirm = withFallback(
  React.lazy(() => import("~/pages/Orders/PendingConfirm"))
);
const PendingConfirmPage = withFallback(
  React.lazy(() => import("~/pages/Orders/PendingConfirm/PendingConfirmPage"))
);
const Packaging = withFallback(
  React.lazy(() => import("~/pages/Orders/SearchPendingPackaging/Packaging"))
);
const SearchPendingShipping = withFallback(
  React.lazy(() => import("~/pages/Orders/SearchPendingShipping"))
);
const Shipping = withFallback(
  React.lazy(() => import("~/pages/Orders/SearchPendingShipping/Shipping"))
);
const SearchPendingDelivery = withFallback(
  React.lazy(() => import("~/pages/Orders/SearchPendingDelivery"))
);
const Dilevery = withFallback(
  React.lazy(() => import("~/pages/Orders/SearchPendingDelivery/Dilevery"))
);
const ForgotPage = withFallback(
  React.lazy(() => import("~/pages/ForgotPassword"))
);
const NewPassword = withFallback(
  React.lazy(() => import("~/pages/ForgotPassword/ForgotForm/newPasswordform"))
);

const ProductSubcategory = withFallback(
  React.lazy(() => import("~/pages/ProductSubcategory"))
);
const InventoryAlert = withFallback(
  React.lazy(() => import("~/pages/Inventory/InventoryAlert"))
);
const Inventory = withFallback(
  React.lazy(() => import("~/pages/Inventory"))
);
const ProductIncoming = withFallback(
  React.lazy(() => import("~/pages/Inventory/productIncoming"))
);
const ItemBranchTransfer = withFallback(
  React.lazy(() => import("~/pages/ItemBranchTransfer"))
);
const CompanyInventory = withFallback(
  React.lazy(() => import("~/pages/CompanyInventory"))
);
import { useAuth } from "~/contexts/authContext";
import { ProtectedRoute } from "./ProtectedLayout";

const RenderRouter: React.FC = () => {
  const { user } = useAuth();
  return (
    <Routes>
      <Route element={<RegisterLayout />}>
        <Route path="/" element={<Login />} />
      </Route>

      <Route element={<RegisterLayout />}>
        <Route path="/register" element={<Register />} />
      </Route>
      <Route element={<MainLayout />}>
        <Route
          path="/landingPage"
          element={
           
              <LandingPage />
          }
        ></Route>
        {/* /salesSummaryDasboard */}
        <Route>
        <Route path="/salesSummaryDasboard" element={<SalesSummaryDashboard/>} />
      </Route>
      </Route>
      <Route element={<RegisterLayout />}>
        <Route path="/forgotPassword" element={<ForgotPage />} />
      </Route>

      <Route element={<RegisterLayout />}>
        <Route path="/rsetPassword" element={<NewPassword />} />
      </Route>
      <Route element={<MainLayout />}
        /* element={
          <ProtectedRoute user={user} isAllowed={"Customers"}>
            <MainLayout />
          </ProtectedRoute>
        } */
      >
        <Route path="/customers" element={<Customers />} />
        <Route path="/customers/new" element={<Customers />} />
        <Route path="/customers/:id" element={<AddCustomers />} />
        <Route path="/customers/add-customer" element={<AddCustomers />} />
      </Route>
      <Route element={<MainLayout />}>
        <Route path="/notes" element={<Notes/>} />
      </Route>
      <Route element={<MainLayout />}>
        <Route path="/sales" element={<Sales/>} />
      </Route>
      <Route element={<MainLayout />}>
        <Route path="/sales/:id" element={<Sales/>} />
      </Route>
       {/* /salesOrder/Add */}
       <Route element={<MainLayout />}>
        <Route path="/salesOrder/Add" element={<SalesOrderAddPage/>} />
      </Route>
      {/* /salesrder/Edit */}
      <Route element={<MainLayout />}>
        <Route path="/salesOrder/:id" element={<SalesOrderAddPage/>} />
      </Route>
      <Route element={<MainLayout />}>
        <Route path="/customerVisit" element={<CustomerVisit/>} />
      </Route>
      <Route element={<MainLayout />}>
        <Route path="/branch" element={<Branches/>} />
      </Route>
      <Route element={<MainLayout />}>
        <Route path="/itemSettings" element={<ItemSettings/>} />
      </Route>
      <Route element={<MainLayout />}>
        <Route path="/products" element={<ItemMaster/>} />
      </Route>
      {/* /itemMaster/Add */}
      <Route element={<MainLayout />}>
        <Route path="/product/Add" element={<ItemMasterAddPage/>} />
      </Route>
      {/* /itemMaster/Edit */}
      <Route element={<MainLayout />}>
        <Route path="/product/:id" element={<ItemMasterAddPage/>} />
      </Route>
      {/* STOCK ALERT */}
      <Route element={<MainLayout />}>
        <Route path="/productStockAlert" element={<StockAlertProducts/>} />
      </Route>
      <Route element={<MainLayout />}>
        <Route path="/vendorPayments" element={<VendorPayments/>} />
      </Route>
      <Route element={<MainLayout />}>
        <Route path="/purchase" element={<PurchasePage/>} />
      </Route>
      {/* /purchaseOrder/Add */}
      <Route element={<MainLayout />}>
        <Route path="/purchaseOrder/Add" element={<PurchaseAddPage/>} />
      </Route>
      {/* /purchaseOrder/Edit */}
      <Route element={<MainLayout />}>
        <Route path="/purchaseOrder/:id" element={<PurchaseAddPage/>} />
      </Route>
      <Route element={<MainLayout />}>
        <Route path="/itemBranchTransfer" element={<ItemBranchTransfer/>} />
      </Route>
      {/* /itemBranchTransfer/Add */}
      <Route element={<MainLayout />}>
        <Route path="/itemBranchTransferOrder/Add" element={<ItemBranchTransferAddPage/>} />
      </Route>
      {/* itemBranchTransfer/Edit */}
      <Route element={<MainLayout />}>
        <Route path="/itemBranchTransferOrder/:id" element={<ItemBranchTransferAddPage/>} />
      </Route>
      <Route element={<MainLayout />}>
        <Route path="/productProperties" element={<ItemProperties/>} />
      </Route>
      {/* /branchStockInventory */}
      <Route element={<MainLayout />}>
        <Route path="/branchStockInventory" element={<BranchStockInventoryPage/>} />
      </Route>
      {/* BranchStockReceivePage */}
      <Route element={<MainLayout />}>
        <Route path="/branchStockReceive/:id" element={<BranchStockReceivePage/>} />
      </Route>
      {/* /salesOrders */}
      <Route element={<MainLayout />}>
        <Route path="/salesOrders" element={<SalesOrderPage/>} />
      </Route>
      {/* /ITEMINVNETORY==> /itemInventory */}
      <Route element={<MainLayout />}>
        <Route path="/itemInventory" element={<ItemInventoryPage/>} />
      </Route>
      {/* /inventory/Edit */}
      <Route element={<MainLayout />}>
        <Route path="/inventory/Add" element={<AddInventoryForm/>} />
      </Route>
      {/* /inventory/id  */}
      <Route element={<MainLayout />}>
        <Route path="/inventory/:id" element={<AddInventoryForm/>} />
      </Route>
      {/*  */}
      <Route element={<MainLayout />}>
        <Route path="/companyInventory" element={<CompanyInventory/>} />
      </Route>
      <Route element={<MainLayout />}>
        <Route path="/reports" element={<ReportsPage/>} />
      </Route>
      <Route element={<MainLayout />}>
        <Route path="/salesReferralsReport" element={<SalesReferralsPage/>} />
      </Route>   
      <Route element={<MainLayout />}>
        <Route path="/salesReferralsSummaryReport/:id" element={<SalesReferralsSummaryPage/>} />
      </Route>      
      <Route element={<MainLayout />}>
        <Route path="/receivePayment" element={<ReceivePaymentPage/>} />
      </Route>
      {/* checkout */}
      <Route element={<MainLayout />}>
        <Route path="/check-out" element={<CheckOut />} /> 
      </Route>
      {/* checkinput Log */}
      <Route element={<MainLayout />}>
        <Route path="/checkinoutLog" element={<CheckinoutLog/>} />
      </Route>
      {/* Category Summary */}
      <Route element={<MainLayout />}>
        <Route path="/categorySummary" element={<CategoryStockSummary/>} />
      </Route>
      {/* sold qty summary */}
      <Route element={<MainLayout />}>
        <Route path="/soldQtySummary" element={<SoldQtySummary/>} />
      </Route>
      {/* Item Summary */}
      <Route element={<MainLayout />}>
        <Route path="/itemStockSummmary" element={<ItemStockSummary/>} />
      </Route>
      {/* itemStockAlertProductList */}
      <Route element={<MainLayout />}>
        <Route path="/itemStockAlertProductList" element={<ItemProductStockAlert/>} />
      </Route>
      {/* branchwise sales summary */}
      <Route element={<MainLayout />}>
        <Route path="/branchwiseSalesReport" element={<BranchWiseSalesSummary/>} />
      </Route>
      <Route element={<MainLayout />}>
        <Route path="/branchwisetransferReport" element={<BranchwiseDateReport/>} />
      </Route>
      <Route
        element={
          <ProtectedRoute user={user} isAllowed={"Products"}>
            <MainLayout />
          </ProtectedRoute>
        }
      >
        <Route path="/inventory" element={<Inventory />} />
        <Route path="/inventory-alert" element={<InventoryAlert />} />
      </Route>
      <Route
        element={
          <ProtectedRoute user={user} isAllowed={"Products"}>
            <MainLayout />
          </ProtectedRoute>
        }
      >
        <Route path="/product-incoming" element={<ProductIncoming />} />
      </Route>
      <Route
        element={
          <ProtectedRoute user={user} isAllowed={"Order Menu"}>
            <MainLayout />
          </ProtectedRoute>
        }
      >
        <Route path="/orders" element={<Orders />} />
        <Route path="/back-order" element={<BackOrder />} />
        <Route path="/back-order/:id" element={<BackOrderPage />} />
        <Route path="/invoice" element={<Invoice />} />
        <Route path="/invoice/:id" element={<InvoicePage />} />
        <Route path="/orders/add-order" element={<OrdersPage />} />
        <Route path="/orders/:id" element={<OrdersPage />} />
        <Route path="/orders/:id/payement" element={<PayementPage />} />
        <Route path="/New-Orders" element={<NewOrders />} />
        <Route path="/new-orders/:id" element={<NewOrdersPage />} />
        <Route path="/pending-confirm" element={<PendingConfirm />} />
        <Route path="/pending-confirm/:id" element={<PendingConfirmPage />} />
      </Route>
      <Route
        element={
          <ProtectedRoute user={user} isAllowed={"Pending Packaging"}>
            <MainLayout />
          </ProtectedRoute>
        }
      >
        <Route path="/pending-packaging" element={<SearchPendingPackaging />} />
        <Route path="/pending-packaging/:id" element={<Packaging />} />

        <Route path="/delivered-orders" element={<SearchDeliveredOrders />} />
        <Route path="/delivered-orders/:id" element={<DeliveredOrders />} />
      </Route>
      <Route element={<MainLayout />}>
        <Route path="/customer-order" element={<CustomerOrder />} />
        <Route
          path="/customer-order/add-order"
          element={<CustomerOrderEdit />}
        />
        <Route path="/customer-order/:id" element={<CustomerOrderEdit />} />
      </Route>
      <Route
        element={
          <ProtectedRoute user={user} isAllowed={"Pending Shipping"}>
            <MainLayout />
          </ProtectedRoute>
        }
      >
        <Route path="/pending-shipping" element={<SearchPendingShipping />} />
        <Route
          path="/pending-shipping/:id/:shippingID"
          element={<Shipping />}
        />
      </Route>
      <Route
        element={
          <ProtectedRoute user={user} isAllowed={"Pending Delivery"}>
            <MainLayout />
          </ProtectedRoute>
        }
      >
        <Route path="/pending-delivery" element={<SearchPendingDelivery />} />
        <Route
          path="/pending-delivery/:id/:shippingID"
          element={<Dilevery />}
        />
      </Route>
      {/* <Route
        element={
          <ProtectedRoute user={user} isAllowed={"User"}>
            <MainLayout />
          </ProtectedRoute>
        }
      >
        <Route path="/change-password" element={<ChangePassword />} />
        <Route path="/change-name" element={<ChangeName />} />
      </Route> */}

      {/* <Route element={<MainLayout />}>
        <Route path="/pending-shipping" element={<SearchPendingShipping />} />
      </Route>
      <Route element={<MainLayout />}>
        <Route path="/pending-delivery" element={<SearchPendingDelivery />} />
      </Route> */}
      <Route element={<MainLayout />}>
        <Route path="/pricings" element={<Pricings />} />
      </Route>
      <Route
        element={
          <ProtectedRoute user={user} isAllowed={"Product Type"}>
            <MainLayout />
          </ProtectedRoute>
        }
      >
        <Route path="/product-type" element={<ProductType />} />
      </Route>
      
      <Route
        element={
          <ProtectedRoute user={user} isAllowed={"Product Category"}>
            <MainLayout />
          </ProtectedRoute>
        }
      >
        <Route path="/product-category" element={<ProductCategory />} />
      </Route>
      <Route
        element={
          <ProtectedRoute user={user} isAllowed={"Document Type"}>
            <MainLayout />
          </ProtectedRoute>
        }
      >
        <Route path="/document-type" element={<DocumentType />} />
      </Route>
      <Route
        element={<MainLayout />}
      >
        <Route path="/user" element={<User />} />
        <Route path="/user/:id" element={<UserManager />} />
      </Route>
      <Route
        element={        
            <MainLayout />          
        }
      >
        <Route path="/vendor" element={<Vendor />} />
      </Route>

            {/* agents */}
      <Route element={<MainLayout />}>
        <Route path="/salesReferrals" element={<Agent />} />
      </Route>
      <Route element={<MainLayout />}>
      <Route path="/change-password" element={<ChangePassword />} />
      </Route>
      <Route element={<MainLayout />}>
        <Route path="/change-name" element={<ChangeName />} />
        </Route>
      <Route
        element={
          <ProtectedRoute user={user} isAllowed={"Ship Type"}>
            <MainLayout />
          </ProtectedRoute>
        }
      >
        {/* <Route path="/about" element={<About />} /> */}
        <Route path="/ship-type" element={<ShipType />} />
        <Route path="/sku" element={<SKU />} />
      </Route>

      {/* Product Subcategory */}
      <Route
        element={
          <ProtectedRoute user={user} isAllowed={"Product Sub Category"}>
            <MainLayout />
          </ProtectedRoute>
        }
      >
        <Route path="/Product-Subcategory" element={<ProductSubcategory />} />
      </Route>
    </Routes>
  );
};

export default RenderRouter;
